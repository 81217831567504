import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useVoidQuote = () => {
  const mutation = useMutation((quoteId) => {
    return axiosClient.post(`/quote/void/${quoteId}`);
  });
  return mutation;
};
export default useVoidQuote;
