import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Skeleton } from "antd";
import { useDispatch } from "react-redux";
import useParameters from "../../core/hooks/useParameters";
import {
  WrapperContent,
  WrapperLayout,
  Sider,
  Content,
  ProfileCard,
  WrapperLogout,
  WrapperRights,
  ProfileImage,
} from "./styles";
import { Div, Text, Col } from "../../styles/Common";
import logo from "../../assets/images/dividermLogoDarkMode.svg";
import loginwave from "../../assets/images/wave-path.svg";
import { WaveWrapper } from "./styles";
import { useMediaQuery } from "react-responsive";
import {
  HomeIcon,
  ClinicIcon,
  UserIcon,
  SettingsIcon,
  ClientsIcon,
  ReportsIcon,
  CopyrightIcon,
  LogoutIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
} from "../../assets/icons";
import { Link } from "../Link";
import { Button } from "../Button";
import { theme } from "../../styles/theme";
import { deleteLocalUser } from "../../core/services/axiosInstance";
import {
  isSuperAdmin,
  isAccountOwner,
  isAdmin,
  isRegular,
  getUserInfo,
  isProvider,
} from "../../utilities/helpers";
import useGetProfile from "../../pages/Profile/hooks/useGetProfile";
import { setClinicsListInformation } from "../../core/store/common";
import { setCodesListInformation } from "../../core/store/common";
import { setSelectedClinicInformation } from "../../core/store/clinics";

export const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery({
    query: `(max-width: 1320px)`,
  });

  const getClinics = useParameters("clinics");
  const { data: dataClinics, isLoading: isLoadingClinics } = getClinics;
  useEffect(() => {
    if (isSuperAdmin && dataClinics) {
      dispatch(
        setClinicsListInformation(
          dataClinics?.data?.data?.clinics.map((ele) => {
            return {
              value: ele.id,
              label: ele.name,
            };
          })
        )
      );
    }
  }, [dataClinics]);

  const getCodes = useParameters("phone_codes");
  const { data: dataCodes, isLoading: isLoadingCodes } = getCodes;

  useEffect(() => {
    if (isSuperAdmin && dataCodes) {
      dispatch(
        setCodesListInformation(
          dataCodes?.data?.data?.phone_codes.map((ele) => {
            return {
              value: ele.code,
              label: ele.code,
            };
          })
        )
      );
    }
  }, [dataCodes]);

  const getProfile = useGetProfile();
  const { data: dataProfile, isLoading } = getProfile;
  const [profile, setProfile] = useState({});

  const [isLayoutOpen, setIsLayoutOpen] = useState(!isSmallScreen);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  useEffect(() => {
    if (dataProfile) {
      setProfile(dataProfile?.data?.data);
    }
  }, [dataProfile]);

  const imageProfile = useMemo(() => {
    const urlImage =
      profile?.user?.image && profile?.user?.image.includes("http")
        ? `${profile?.user?.image}?${new Date().getTime()}`
        : null;
    return urlImage;
  }, [profile]);

  const handleLogout = () => {
    deleteLocalUser();
    navigate("/");
  };

  const items = [
    {
      icon: (colorIcon) => <HomeIcon fill={colorIcon} stroke={colorIcon} />,
      name: "Home",
      permission: true,
      handleClick: () => {
        setIsSubMenuOpen(false);
        navigate("/home");
      },
      path: "/home",
    },
    {
      icon: (colorIcon) => <ClinicIcon fill={colorIcon} stroke={colorIcon} />,
      name: "Clinics",
      permission: isSuperAdmin(),
      handleClick: () => {
        setIsSubMenuOpen(false);
        navigate("/clinics");
      },
      path: "/clinics",
    },
    {
      icon: (colorIcon) => <ClinicIcon fill={colorIcon} stroke={colorIcon} />,
      name: "My Clinic",
      permission: isAccountOwner(),
      handleClick: () => {
        setIsSubMenuOpen(false);
        dispatch(
          setSelectedClinicInformation({
            clinic: getUserInfo().clinic,
            user: {
              lastName: getUserInfo().lastname,
              name: getUserInfo().name,
              phone: null,
            },
            email: getUserInfo().email,
          })
        );
        navigate("clinics/edit-clinic");
      },
      path: "/clinics",
    },
    {
      icon: (colorIcon) => <UserIcon stroke={colorIcon} />,
      name: "Users",
      permission: isSuperAdmin() || isAdmin() || isAccountOwner(),
      handleClick: () => {
        setIsSubMenuOpen(false);
        navigate("/users");
      },
      path: "/users",
    },
    {
      icon: (colorIcon) => <ClientsIcon stroke={colorIcon} />,
      name: "Patients",
      permission:
        isSuperAdmin() ||
        isAdmin() ||
        isAccountOwner() ||
        isRegular() ||
        isProvider(),
      handleClick: () => {
        setIsSubMenuOpen(false);
        navigate("/patients");
      },
      path: "/patients",
    },
    {
      icon: (colorIcon) => <SettingsIcon stroke={colorIcon} />,
      name: "General Management",
      permission: isSuperAdmin() || isAdmin() || isAccountOwner(),
      handleClick: () => {
        setIsSubMenuOpen(!isSubMenuOpen);
      },
      path: null,
    },
    {
      icon: (colorIcon) => <ReportsIcon stroke={colorIcon} />,
      name: "Quote History",
      permission:
        isSuperAdmin() || isAdmin() || isAccountOwner() || isRegular(),
      handleClick: () => {
        setIsSubMenuOpen(false);
        navigate("/quote-history");
      },
      path: "/quote-history",
    },
  ];

  const generalManagementSubMenuItems = [
    {
      name: "Categories",
      path: "/categories",
      handleClick: () => {
        navigate("/categories");
      },
    },
    {
      name: "Products",
      path: "/products",
      handleClick: () => {
        navigate("/products");
      },
    },
    {
      name: "Treatments",
      path: "/treatments",
      handleClick: () => {
        navigate("/treatments");
      },
    },
    {
      name: "Discounts",
      path: "/discounts",
      handleClick: () => {
        navigate("/discounts");
      },
    },
    {
      name: "Treatment Plans",
      path: "/plans",
      handleClick: () => {
        navigate("/plans");
      },
    },
    {
      name: "Packages",
      path: "/packages",
      handleClick: () => {
        navigate("/packages");
      },
    },
  ];

  return (
    <WrapperLayout>
      <>
        <WrapperContent>
          {isLayoutOpen && (
            <WaveWrapper>
              <Div
                align={"left"}
                style={{ position: "absolute", bottom: "0px", left: "0px" }}
              >
                <img
                  alt="logintext"
                  src={loginwave}
                  width="120px"
                  height="560px"
                />
              </Div>
            </WaveWrapper>
          )}
          <Sider isLayoutOpen={isLayoutOpen}>
            <Link
              height="40px"
              width="40px"
              radius="28px 0 0 28px"
              onClick={() => {
                setIsLayoutOpen(!isLayoutOpen);
              }}
              backgroundhover={theme.colors.gray900}
              background={theme.colors.gray700}
              style={{
                position: "absolute",
                top: "10px",
                left: isLayoutOpen ? "255px" : "5px",
                zIndex: 999,
              }}
            >
              {isLayoutOpen ? (
                <ChevronLeftIcon
                  stroke={theme.colors.gray500}
                  width={"40px"}
                  height={"40px"}
                />
              ) : (
                <ChevronRightIcon
                  stroke={theme.colors.gray500}
                  width={"40px"}
                  height={"40px"}
                />
              )}
            </Link>

            <>
              <Div
                width={isLayoutOpen ? "220px" : "50px"}
                height="65px"
                m={isLayoutOpen ? "4px 40px 14px 30px" : "34px 40px 44px 30px"}
              >
                {isLayoutOpen && <img alt="logo" src={logo} />}
              </Div>

              {items.map((item, ind) => {
                if (item.name === "General Management" && item.permission) {
                  return (
                    <Div
                      key={ind}
                      direction="column"
                      width={isLayoutOpen ? "240px" : "50px"}
                      m="0"
                    >
                      <Link
                        gap="6px"
                        height="50px"
                        width={isLayoutOpen ? "240px" : "30px"}
                        padding={isLayoutOpen ? "0px 0px 0px 16px" : "0px"}
                        m={
                          !isSubMenuOpen
                            ? "0px 0px 16px 20px"
                            : "0px 0px 0 20px"
                        }
                        radius={isLayoutOpen ? "28px" : "10px"}
                        color={
                          isSubMenuOpen
                            ? theme.colors.green
                            : theme.colors.gray500
                        }
                        size={theme.fonts.size.sm}
                        onClick={() => {
                          setIsSubMenuOpen(!isSubMenuOpen);
                        }}
                        backgroundhover={
                          isSubMenuOpen
                            ? theme.colors.gray700
                            : theme.colors.gray900
                        }
                        background={
                          isSubMenuOpen ? theme.colors.gray700 : "transparent"
                        }
                        icon={item.icon(
                          isSubMenuOpen
                            ? theme.colors.green
                            : theme.colors.gray500
                        )}
                      >
                        <Div align="center" gap="6px">
                          {isLayoutOpen && item.name}
                          {isLayoutOpen ? (
                            isSubMenuOpen ? (
                              <ChevronDownIcon stroke={theme.colors.green} />
                            ) : (
                              <ChevronRightIcon stroke={theme.colors.gray500} />
                            )
                          ) : (
                            <></>
                          )}
                        </Div>
                      </Link>
                      {isSubMenuOpen && (
                        <Div
                          direction="column"
                          width={isLayoutOpen ? "240px" : "50px"}
                          align="end"
                        >
                          {generalManagementSubMenuItems.map(
                            (subItem, subInd) => (
                              <Link
                                key={subInd}
                                gap="6px"
                                height="50px"
                                width={isLayoutOpen ? "200px" : "50px"}
                                padding="0px 0px 0px 25px"
                                m="0px 0px 0px 20px"
                                radius={isLayoutOpen ? "28px" : "10px"}
                                color={
                                  pathname.includes(subItem.path)
                                    ? theme.colors.green
                                    : theme.colors.gray500
                                }
                                size={theme.fonts.size.sm}
                                onClick={subItem.handleClick}
                                backgroundhover={theme.colors.gray700}
                                background={
                                  pathname.includes(subItem.path)
                                    ? theme.colors.gray700
                                    : "transparent"
                                }
                              >
                                <Div
                                  align="center"
                                  justify="space-between"
                                  width={isLayoutOpen ? "240px" : "50px"}
                                >
                                  <Div>
                                    {isLayoutOpen
                                      ? subItem.name
                                      : subItem.name.substring(0, 1)}
                                  </Div>
                                  {isLayoutOpen && (
                                    <ChevronRightIcon
                                      stroke={
                                        pathname.includes(subItem.path)
                                          ? theme.colors.green
                                          : theme.colors.gray500
                                      }
                                    />
                                  )}
                                </Div>
                              </Link>
                            )
                          )}
                        </Div>
                      )}
                    </Div>
                  );
                } else {
                  return (
                    item.permission && (
                      <Div key={ind}>
                        <Link
                          gap="6px"
                          height="50px"
                          width={isLayoutOpen ? "240px" : "30px"}
                          padding="0px 0px 0px 16px"
                          m="0px 0px 16px 20px"
                          radius={isLayoutOpen ? "28px" : "10px"}
                          color={
                            pathname.includes(item.path) && !isSubMenuOpen
                              ? theme.colors.green
                              : theme.colors.gray500
                          }
                          size={theme.fonts.size.sm}
                          onClick={item.handleClick}
                          backgroundhover={
                            pathname.includes(item.path) && !isSubMenuOpen
                              ? theme.colors.gray700
                              : theme.colors.gray900
                          }
                          background={
                            pathname.includes(item.path) && !isSubMenuOpen
                              ? theme.colors.gray700
                              : "transparent"
                          }
                          icon={item.icon(
                            pathname.includes(item.path) && !isSubMenuOpen
                              ? theme.colors.green
                              : theme.colors.gray500
                          )}
                        >
                          {isLayoutOpen && item.name}
                        </Link>
                      </Div>
                    )
                  );
                }
              })}
              {!isProvider() &&
                (isLayoutOpen && pathname.includes("/quoting-tool") ? (
                  <Div
                    width="240px"
                    padding="0px 0px 0px 16px"
                    m="11px 0px 0px 20px"
                    radius="28px"
                    style={{ "min-height": "50px" }}
                  />
                ) : (
                  <Button
                    width={isLayoutOpen ? "240px" : "30px"}
                    padding="0px 0px 0px 16px"
                    m="11px 0px 0px 20px"
                    radius={isLayoutOpen ? "28px" : "10px"}
                    color={theme.colors.green}
                    size={theme.fonts.size.default}
                    weight={theme.fonts.weight.semibold}
                    background={theme.colors.green100}
                    border={theme.colors.green}
                    style={{ "min-height": "50px" }}
                    onClick={() => navigate("/quoting-tool")}
                  >
                    {isLayoutOpen ? "Quoting Tool" : "Q"}
                  </Button>
                ))}

              {isLayoutOpen ? (
                isLoading || isLoadingClinics || isLoadingCodes ? (
                  <ProfileCard to="/profile">
                    <Div
                      gap="10px"
                      align="center"
                      width="100%"
                      height="100%"
                      p="10px"
                    >
                      <Skeleton.Avatar active size={45} />
                      <Skeleton active />
                    </Div>
                  </ProfileCard>
                ) : (
                  <ProfileCard to="/profile" isLayoutOpen={isLayoutOpen}>
                    {imageProfile ? (
                      <ProfileImage
                        key={imageProfile}
                        background={imageProfile}
                      />
                    ) : (
                      <ProfileImage>
                        <UserIcon stroke={theme.colors.green} />
                      </ProfileImage>
                    )}
                    {isLayoutOpen && (
                      <Col m="0 10px 0 16px" width={"115px"}>
                        <Text
                          color={theme.colors.green}
                          size={theme.fonts.size.default}
                          weight={theme.fonts.weight.medium}
                          style={{ "white-space": "nowrap" }}
                        >
                          {profile?.user?.name} {profile?.user?.lastname}
                        </Text>
                        <Text
                          color={theme.colors.gray500}
                          size={theme.fonts.size.sm}
                          weight={theme.fonts.weight.medium}
                        >
                          Profile
                        </Text>
                      </Col>
                    )}
                    <ChevronRightIcon stroke={theme.colors.gray500} />
                  </ProfileCard>
                )
              ) : (
                <ProfileCard to="/profile" isLayoutOpen={isLayoutOpen}>
                  <UserIcon stroke={theme.colors.gray500} />
                </ProfileCard>
              )}

              <WrapperLogout onClick={handleLogout} isLayoutOpen={isLayoutOpen}>
                {isLayoutOpen && (
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.gray500}
                  >
                    Logout
                  </Text>
                )}
                <LogoutIcon />
              </WrapperLogout>
              {isLayoutOpen && (
                <WrapperRights>
                  <CopyrightIcon />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.gray500}
                  >
                    2024. All Rights Reserved
                  </Text>
                </WrapperRights>
              )}
            </>
          </Sider>
          <Content isLayoutOpen={isLayoutOpen}> {children}</Content>
        </WrapperContent>
      </>
    </WrapperLayout>
  );
};
