import { PhotoCard } from "../../styles";
import { AddPhotoIcon } from "../../../../../assets/icons";
import { theme } from "../../../../../styles/theme";
import { Col, Div, Row, Text } from "../../../../../styles/Common";
import { Upload } from "antd";
import { Input } from "../../../../../components/Input";
import { Radio } from "../../../../../components/Radio";
import {
  convertRoleName,
  isAccountOwner,
  isSuperAdmin,
} from "../../../../../utilities/helpers";

const FormUserCard = ({
  handleUploadLogo,
  imageLogo,
  prevImageLogo,
  handleChangeUser,
  userForm,
  errorForm,
  handleRadio,
  roles,
}) => {
  return (
    <Col m="0px 28px 0px 28px" width="100%">
      <Row
        align="center"
        m="0px 0px 10px 0px"
        style={{ justifyContent: "center" }}
      >
        <Upload
          multiple={false}
          showUploadList={false}
          customRequest={handleUploadLogo}
        >
          {imageLogo ? (
            <PhotoCard background={prevImageLogo} />
          ) : (
            <PhotoCard>
              <AddPhotoIcon stroke={theme.colors.gray500} />
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray500}
              >
                Add photo
              </Text>
            </PhotoCard>
          )}
        </Upload>
      </Row>
      <Row align="center" m="0px 0px 15px 0px">
        <Input
          label={"First Name*"}
          id="name"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          width="100%"
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          value={userForm.name}
          onChange={handleChangeUser}
          error={errorForm.name.error}
          helper={errorForm.name.error ? errorForm.name.message : ""}
        />
      </Row>
      <Row align="center" m="0px 0px 16px 0px">
        <Input
          label={"Last Name*"}
          id="lastname"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          width="100%"
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          value={userForm.lastname}
          onChange={handleChangeUser}
          error={errorForm.lastname.error}
          helper={errorForm.lastname.error ? errorForm.lastname.message : ""}
        />
      </Row>
      <Row align="center" m="0px 0px 16px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
        >
          Role*
        </Text>
      </Row>
      <Row m="0px 0px 10px 0px">
        <Radio.Group
          name="role"
          onChange={handleRadio}
          gap={"3px"}
          direction="column"
          value={userForm.role}
          error={errorForm.role.error}
          helper={errorForm.role.error ? errorForm.role.message : ""}
        >
          {roles
            .filter((role) => role.name !== "SuperAdmin")
            .map((role, index) => (
              <Row gap="8px">
                <Radio
                  value={role.id}
                  key={role.id}
                  disabled={
                    isAccountOwner() ||
                    role.name === "AccountOwner" ||
                    roles.find((rol) => rol.id === userForm.role).name ===
                      "AccountOwner"
                  }
                />

                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  {convertRoleName[role.name]}
                </Text>
                {index < roles.length - 1 && <Div m="0 10px 0 0" />}
              </Row>
            ))}
        </Radio.Group>
      </Row>
    </Col>
  );
};

export default FormUserCard;
