export const theme = {
  colors: {
    white: "#FFFFFF",
    white100: "#FFFFFF29",
    white200: "#00000000",
    black: "#000000",

    yellow40: "rgb(237, 211, 12, 0.4)",
    yellow: "#edd30c",

    green: "#2CBBAF",
    green40: "rgb(44, 187, 175, 0.4)",
    green100: "#2CBBAF1A",
    green300: "#2DBBAF",
    green500: "#00625A",

    gray50: "#F0F0F0",
    gray100: "#D9D9D9",
    gray200: "#00000017",
    gray300: "#00000029",
    gray350: "#F5F5F5",
    gray400: "#D9D9D9",
    gray500: "#8D8D8D",
    gray600: "#707070",
    gray700: "#363636",
    gray750: "#464646",
    gray800: "#484848",
    gray850: "#2F2F2F",
    gray900: "#3C3C3C",
    gray950: "#272727",

    purple: "#a59acf",

    red: "#F87C7C",
    red40: "rgb(248, 124, 124, 0.4)",

    blue: "#00B1FF",
    blue40: "rgb(0, 179, 255, 0.4)",

    scroll: {
      gray: "#D9D9D9",
      green: "#00565F",
    },
    alerts: {
      success: "#CADDD4",
      error: "#FFE5E5",
      iconSuccess: "#4A7668",
      iconError: "#F87C7C",
    },
  },

  fonts: {
    family: "Montserrat, sans-serif",
    size: {
      h1: "2.625rem",
      h2: "2rem",
      h3: "1.6875rem",
      h4: "1.5rem",
      h5: "1.125rem",
      h6: "1.0625rem",
      default: "1rem",
      sm: "0.875rem",
      xs: "0.75rem",
    },
    weight: {
      extralight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
  },
  breakpoints: {
    xs: "320px",
    sm: "480px",
    md: "820px",
    lg: "992px",
    xl: "1200px",
  },
  zIndex: {
    base: 1,
    menu: 2,
    overlay: 3,
    modal: 4,
    toast: 5,
  },
};
