import React from "react";
import { theme } from "../../../../../styles/theme";
import { Col, Container, Row, Div, Text } from "../../../../../styles/Common";
import { ButtonSavings, ItemsCard, TableRow } from "../../styles";
import { toast } from "react-hot-toast";
import { Button } from "../../../../../components/Button";
import { Link } from "../../../../../components/Link";
import {
  AddIcon,
  CalculatorIcon,
  CloseIcon,
  DecreaseIcon,
} from "../../../../../assets/icons";
import { formatPriceToDollar } from "../../../../../utilities/helpers";
import _ from "lodash";
import { Input } from "../../../../../components/Input";

export const ItemsInfo = ({
  setShowModal,
  setShowModalOpenItems,
  quotation,
  setQuotation,
  saveQuoteErrorForm,
  openItems,
  setOpenItems,
  GlobalPackageName,
  setSelectedSavings,
  setShowModalSavings,
}) => {
  const combinedItems = _.groupBy(
    [...quotation?.items, ...openItems],
    "package"
  );

  const handleUpdateQuantity = (packageName, itemIndex, action, isOpenItem) => {
    if (!isOpenItem) {
      const updatedQuotation = { ...quotation };
      const updatedItems = [...updatedQuotation.items];
      const packageItems = combinedItems[packageName];
      const globalIndex = updatedItems.findIndex(
        (item) =>
          item.package === packageName &&
          item.name === packageItems[itemIndex].name
      );
      if (action === "increase") {
        updatedItems[globalIndex].quantity += 1;
      } else if (
        action === "decrease" &&
        updatedItems[globalIndex].quantity > 1
      ) {
        updatedItems[globalIndex].quantity -= 1;
      }
      updatedQuotation.items = updatedItems;
      setQuotation(updatedQuotation);
    } else {
      const updatedItems = [...openItems];
      const packageItems = combinedItems[packageName];
      const globalIndex = updatedItems.findIndex(
        (item) =>
          item.package === packageName &&
          item.name === packageItems[itemIndex].name
      );
      const unitaryTax =
        packageItems[itemIndex].tax / packageItems[itemIndex].quantity;

      if (action === "increase") {
        updatedItems[globalIndex].quantity += 1;
      } else if (
        action === "decrease" &&
        updatedItems[globalIndex].quantity > 1
      ) {
        updatedItems[globalIndex].quantity -= 1;
      }
      updatedItems[globalIndex].tax =
        unitaryTax * updatedItems[globalIndex].quantity;
      setOpenItems(updatedItems);
    }
  };

  const handleDeleteItem = (deletedItem, isOpenItem) => {
    if (!isOpenItem) {
      const updatedQuotation = { ...quotation };
      const updatedItems = quotation.items.filter(
        (item) => item !== deletedItem
      );
      updatedQuotation.items = updatedItems;
      setQuotation(updatedQuotation);
    } else {
      const updatedItems = openItems.filter((item) => item !== deletedItem);
      setOpenItems(updatedItems);
    }

    toast.success("Item successfully deleted");
  };

  return (
    <ItemsCard style={{ margin: "25px" }}>
      <Row m="0 0 18px 0">
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h5}
          color={theme.colors.gray50}
        >
          Item detail
        </Text>
      </Row>
      <Row m="0 0 11px 0" justify="center" width="680px">
        {quotation.items.length === 0 && openItems.length === 0 ? (
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={
              saveQuoteErrorForm.items.error
                ? theme.colors.red
                : theme.colors.gray100
            }
          >
            Add the necessary items to complete the quote.
          </Text>
        ) : (
          <Col m="0 0 15px 0">
            <TableRow background="transparent">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray400}
                style={{ width: "100px" }}
              >
                Item Name
              </Text>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray400}
                style={{ width: "100px" }}
              >
                Price
              </Text>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray400}
                style={{ width: "100px" }}
              >
                Quantity
              </Text>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray400}
                style={{ width: "82px" }}
              >
                Subtotal
              </Text>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray400}
                style={{ width: "60px" }}
              >
                Tax
              </Text>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray400}
                style={{ width: "92px" }}
              >
                Savings
              </Text>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray400}
                style={{ width: "100px" }}
              >
                Total Price
              </Text>
            </TableRow>
            {Object.entries(combinedItems).map(
              ([packageName, items], packageIndex) => (
                <Col key={packageIndex}>
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray400}
                    mb="10px"
                  >
                    {packageName}
                  </Text>
                  {items.map((item, index) => (
                    <TableRow
                      key={index}
                      background={
                        index % 2 === 0 ? theme.colors.gray600 : "transparent"
                      }
                    >
                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.sm}
                        color={theme.colors.gray400}
                        style={{ width: "110px", "white-space": "nowrap" }}
                      >
                        {item.name}
                      </Text>
                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.sm}
                        color={theme.colors.gray400}
                        style={{ width: "100px" }}
                      >
                        {formatPriceToDollar(item.price)}
                      </Text>
                      <Row
                        width={item.belongsToPkg ? "85px" : "110px"}
                        justify="center"
                        gap="5px"
                      >
                        <Link
                          onClick={() =>
                            handleUpdateQuantity(
                              packageName,
                              index,
                              "decrease",
                              item?.isOpenItem ? item.isOpenItem : false
                            )
                          }
                        >
                          <DecreaseIcon
                            stroke={theme.colors.gray400}
                            width="15px"
                            height="15px"
                          />
                        </Link>
                        <Text
                          weight={theme.fonts.weight.medium}
                          size={theme.fonts.size.sm}
                          color={theme.colors.gray400}
                          style={{
                            border: `1px solid ${theme.colors.gray500}`,
                            "border-radius": "25px",
                            width: "40px",
                          }}
                        >
                          {item.quantity}
                        </Text>
                        <Link
                          onClick={() =>
                            handleUpdateQuantity(
                              packageName,
                              index,
                              "increase",
                              item?.isOpenItem ? item.isOpenItem : false
                            )
                          }
                        >
                          <AddIcon
                            stroke={theme.colors.gray400}
                            width="15px"
                            height="15px"
                          />
                        </Link>
                      </Row>
                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.sm}
                        color={theme.colors.gray400}
                        style={{ width: "82px" }}
                      >
                        {formatPriceToDollar(item.price * item.quantity)}
                      </Text>
                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.sm}
                        color={theme.colors.gray400}
                        style={{ width: "70px" }}
                      >
                        {formatPriceToDollar(item.tax)}
                      </Text>
                      <ButtonSavings
                        onClick={() => {
                          setSelectedSavings({
                            discount: item.discount,
                            subtotal: item.price * item.quantity,
                            packageName: packageName,
                            itemIndex: index,
                            isOpenItem: item?.isOpenItem
                              ? item.isOpenItem
                              : false,
                          });
                          setShowModalSavings(true);
                        }}
                      >
                        <CalculatorIcon
                          fill={theme.colors.gray400}
                          width={"15px"}
                          height={"15px"}
                        />
                        {formatPriceToDollar(item.discount)}
                      </ButtonSavings>

                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.sm}
                        color={theme.colors.gray400}
                        style={{ width: "88px" }}
                      >
                        {formatPriceToDollar(
                          item.price * item.quantity + item.tax - item.discount
                        )}
                      </Text>
                      <Link
                        onClick={() =>
                          handleDeleteItem(
                            item,
                            item?.isOpenItem ? item.isOpenItem : false
                          )
                        }
                      >
                        <CloseIcon
                          stroke={theme.colors.gray400}
                          width="20px"
                          height="20px"
                        />
                      </Link>
                    </TableRow>
                  ))}
                  <TableRow background={"transparent"}>
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.sm}
                      color={theme.colors.green}
                      style={{ width: "100px", "white-space": "nowrap" }}
                    >
                      TOTAL
                    </Text>
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.sm}
                      color={theme.colors.green}
                      style={{ width: "100px" }}
                    >
                      {formatPriceToDollar(_.sumBy(items, "price"))}
                    </Text>
                    <Row width="100px" justify="center" gap="5px">
                      <Text
                        weight={theme.fonts.weight.semibold}
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                        style={{
                          border: `1px solid ${theme.colors.green}`,
                          "border-radius": "25px",
                          width: "130px",
                        }}
                      >
                        {_.sumBy(items, "quantity")}
                      </Text>
                    </Row>
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.sm}
                      color={theme.colors.green}
                      style={{ width: "82px" }}
                    >
                      {formatPriceToDollar(
                        _.sumBy(items, (item) => item.price * item.quantity)
                      )}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.sm}
                      color={theme.colors.green}
                      style={{ width: "66px" }}
                    >
                      {formatPriceToDollar(_.sumBy(items, "tax"))}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.sm}
                      color={theme.colors.green}
                      style={{ width: "108px" }}
                    >
                      {formatPriceToDollar(
                        _.sumBy(
                          items.map((item) => Number(item.discount)),
                          (discount) => discount
                        )
                      )}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.sm}
                      color={theme.colors.green}
                      style={{ width: "100px" }}
                    >
                      {formatPriceToDollar(
                        _.sumBy(items, (item) => {
                          const subtotal = item.price * item.quantity;
                          const taxes = item.tax || 0;
                          const discount = Number(item.discount);
                          return subtotal + taxes - discount;
                        })
                      )}
                    </Text>
                  </TableRow>
                  <Div
                    height="1px"
                    background={theme.colors.gray600}
                    m="10px 0"
                  />
                </Col>
              )
            )}
          </Col>
        )}
      </Row>
      {GlobalPackageName === "" ? (
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={
            saveQuoteErrorForm.items.error
              ? theme.colors.red
              : theme.colors.gray100
          }
          style={{ textAlign: "center" }}
        >
          Add package name to add items
        </Text>
      ) : (
        <Row justify="space-between" width="680px" align="flex-end">
          <Link
            gap="5px"
            style={{ borderBottom: `1px solid  ${theme.colors.gray500}` }}
            onClick={() => setShowModalOpenItems(true)}
          >
            <AddIcon
              stroke={theme.colors.gray500}
              width={"16px"}
              height={"16px"}
            />
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Add open item
            </Text>
          </Link>
          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.green}
            background={theme.colors.green100}
            border={theme.colors.green}
            onClick={() => setShowModal(true)}
            width="155px"
          >
            <Div gap={"8px"} style={{ justifyContent: "center" }}>
              <AddIcon stroke={theme.colors.green} />
              <Text
                weight={theme.fonts.weight.semibold}
                size={theme.fonts.size.default}
                color={theme.colors.green}
              >
                Add Item
              </Text>
            </Div>
          </Button>
        </Row>
      )}
    </ItemsCard>
  );
};
