import { useState, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import _ from "lodash";
import { Col, Row, Text, Div } from "../../../../../styles/Common";
import { Input } from "../../../../../components/Input";
import { Modal } from "../../../../../components/Modal";
import { Button } from "../../../../../components/Button";
import { theme } from "../../../../../styles/theme";
import { Radio } from "../../../../../components/Radio";
import { validateSavingsForm } from "../../../../../utilities/validations";
import { formatPriceToDollar } from "../../../../../utilities/helpers";

const ModalCalculator = ({
  showModal,
  handleCloseModal,
  selectedSavings,
  quotation,
  setQuotation,
  openItems,
  setOpenItems,
}) => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const [savingsForm, setSavingsForm] = useState({
    type: "dollar",
    savingsValue: null,
  });
  const [errorForm, setErrorForm] = useState({
    type: { error: false, message: "" },
    savingsValue: { error: false, message: "" },
  });

  useEffect(() => {
    setSavingsForm({
      type: "dollar",
      savingsValue: selectedSavings?.discount,
    });
  }, [selectedSavings]);

  const combinedItems = _.groupBy(
    [...quotation?.items, ...openItems],
    "package"
  );

  const handleRadio = (event) => {
    const { value, name } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[name].error = false;
    newErrorForm[name].message = "";
    setErrorForm(newErrorForm);

    const newItemForm = { ...savingsForm };
    if (value === "percentage") {
      newItemForm["savingsValue"] = parseFloat(
        ((selectedSavings?.discount / selectedSavings?.subtotal) * 100).toFixed(
          2
        )
      );
    } else {
      newItemForm["savingsValue"] = selectedSavings?.discount;
    }
    newItemForm[name] = value;
    setSavingsForm(newItemForm);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newItemForm = { ...savingsForm };
    newItemForm[id] = value;
    setSavingsForm(newItemForm);
    setForceUpdate(!forceUpdate);
  };

  const calculateTotalAfterSaving = () => {
    let total = selectedSavings?.subtotal;
    if (savingsForm?.type === "dollar") {
      total -= savingsForm?.savingsValue;
    } else {
      total = (total * (100 - savingsForm?.savingsValue)) / 100;
    }
    return formatPriceToDollar(total);
  };

  const handleAddItem = () => {
    toast.remove();

    const validation = validateSavingsForm.validate(savingsForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      const value =
        savingsForm?.type === "dollar"
          ? savingsForm?.savingsValue
          : (selectedSavings?.subtotal * savingsForm?.savingsValue) / 100;

      if (!selectedSavings?.isOpenItem) {
        const updatedQuotation = { ...quotation };
        const updatedItems = [...updatedQuotation.items];
        const packageItems = combinedItems[selectedSavings?.packageName];
        const globalIndex = updatedItems.findIndex(
          (item) =>
            item.package === selectedSavings?.packageName &&
            item.name === packageItems[selectedSavings?.itemIndex].name
        );
        updatedItems[globalIndex].discount = Number(value);

        updatedQuotation.items = updatedItems;
        setQuotation(updatedQuotation);
      } else {
        const updatedItems = [...openItems];
        const packageItems = combinedItems[selectedSavings?.packageName];
        const globalIndex = updatedItems.findIndex(
          (item) =>
            item.package === selectedSavings?.packageName &&
            item.name === packageItems[selectedSavings?.itemIndex].name
        );
        updatedItems[globalIndex].discount = Number(value);
        setOpenItems(updatedItems);
      }
      handleCloseModal();
    }
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal} width={"405px"}>
      <Modal.Body
        margin="18px 0 0 0"
        padding="0 50px"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" justify="center">
          <Row width="100%" m="16px 0px 0px 0px" gap="20px">
            <Radio.Group
              name="type"
              value={savingsForm.type}
              gap={"20px"}
              direction="row"
              onChange={handleRadio}
              error={errorForm.type.error}
              helper={errorForm.type.error ? errorForm.type.message : ""}
            >
              <Radio
                value={"dollar"}
                background={theme.colors.white}
                label={"Dollar"}
              />

              <Radio
                value={"percentage"}
                background={theme.colors.white}
                label={"Percentage"}
              />
            </Radio.Group>
          </Row>

          <Row width="100%" m="17px 0px 0px 0px">
            <Input
              label={"Savings"}
              id="savingsValue"
              type="number"
              value={savingsForm.savingsValue}
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"100%"}
              suffix={savingsForm.type === "percentage" && "%"}
              prefix={savingsForm.type === "dollar" && "$"}
              onChange={handleChange}
              error={errorForm.savingsValue.error}
              helper={
                errorForm.savingsValue.error
                  ? errorForm.savingsValue.message
                  : ""
              }
            />
          </Row>

          <Row width="100%" m="17px 0px 0px 0px" justify="space-between">
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Item Line Subtotal
            </Text>
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              {formatPriceToDollar(selectedSavings?.subtotal)}
            </Text>
          </Row>
          <Row width="100%" m="17px 0px 0px 0px" justify="space-between">
            <Text
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Total After Savings:
            </Text>
            <Text
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.default}
              color={theme.colors.green}
            >
              {calculateTotalAfterSaving()}
            </Text>
          </Row>

          <Div
            height="1.2px"
            width="100%"
            background={theme.colors.gray400}
            m="17px 0px 24px 0px"
          />

          <Row width="100%" p="0px 0px 16px 0px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleAddItem}
              width="100%"
              background={theme.colors.green}
            >
              Add Savings
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCalculator;
