import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const QuotingCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 730px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const BilledCard = styled.div`
  display: flex;
  width: 730px;
  padding: 25px;
  background: ${theme.colors.gray600} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  justify-content: space-between;
`;

export const ItemsCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.light};
  width: 730px;
  height: max-content;
`;

export const TotalCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 324px;
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: -webkit-center;
  align-items: center;
  padding: 0 14px 0 14px;
  width: 680px;
  height: 60px;
  background:  ${({ background }) =>
    background || theme.colors.gray600}; 0% 0% no-repeat padding-box;
  border-radius: 11px;
`;

export const LateralCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 164px;
  width: 324px;
  padding: 25px;
  margin: 0 0 24px 0;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const ButtonSavings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 13px;
  width: 108px;
  height: 36px;
  color: ${theme.colors.gray50};
  font-size: ${theme.fonts.size.xs};
  gap: 5px;
  border: 1px solid ${theme.colors.white};
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.white};
    color: ${theme.colors.gray600};
  }

  &:hover > svg {
    fill: ${theme.colors.green};
  }
`;
