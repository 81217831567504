import { useState, useEffect } from "react";
import { Skeleton } from "antd";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Row, Div, Text, Container, Col } from "../../../styles/Common";
import { Link } from "../../../components/Link";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { ChevronLeftIcon } from "../../../assets/icons";
import userCheck from "../../../assets/icons/user-check.svg";
import { theme } from "../../../styles/theme";
import { useNavigate } from "react-router-dom";
import { UserCard, InfoCard, PhotoCard } from "./styles";
import {
  convertFileToBase64,
  createURLFromBase64,
} from "../../../utilities/helpers";
import { validateEditUserForm } from "../../../utilities/validations";
import useParameters from "../../../core/hooks/useParameters";
import useEditUser from "./hooks/useEditUser";
import FormUserCard from "./components/FormUserCard";
import FormInfoCard from "./components/FormInfoCard";

export const EditUser = () => {
  const navigate = useNavigate();
  const editUser = useEditUser();

  const { users, common } = useSelector((state) => state);
  const user = users?.selectedUser?.user || {};
  const optionsClinics = common?.clinicsList || {};

  const [showModal, setShowModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userForm, setUserForm] = useState({
    name: user.name,
    lastname: user.lastname,
    role: users?.selectedUser?.role?.id,
    clinic: users?.selectedUser.clinic?.id.toString(),
    email: users?.selectedUser?.email,
    phone: user.phone ? user.phone : null,
    address: user.address,
    productCommission: user?.product_commission,
    treatmentCommission: user?.treatment_commission,
    image: user.image,
    newPassword: "",
    repeatPassword: "",
  });

  const [errorForm, setErrorForm] = useState({
    name: {
      error: false,
      message: "",
    },
    lastname: {
      error: false,
      message: "",
    },
    role: {
      error: false,
      message: "",
    },
    clinic: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
    phone: {
      error: false,
      message: "",
    },
    image: {
      error: false,
      message: "",
    },
    address: {
      error: false,
      message: "",
    },
    productCommission: {
      error: false,
      message: "",
    },
    treatmentCommission: {
      error: false,
      message: "",
    },
    newPassword: {
      error: false,
      message: "",
    },
    repeatPassword: {
      error: false,
      message: "",
    },
  });

  const [imageLogo, setImageLogo] = useState(null);
  const [prevImageLogo, setPrevImageLogo] = useState(null);
  useEffect(() => {
    if (!users?.selectedUser?.user) {
      navigate("/users");
    } else {
      setImageLogo(user.image);
      setPrevImageLogo(`${user.image}?${new Date().getTime()}`);
    }
  }, [user]);

  const handleUploadLogo = async (dataLogo) => {
    const file = dataLogo.file;
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      toast.error("The image must be .png or .jpg file");
    } else {
      const urlBase64 = await toast.promise(convertFileToBase64(file), {
        loading: "Uploading...",
        success: "Upload completed",
        error: "Upload failed, try again",
      });
      setImageLogo(urlBase64);
      setPrevImageLogo(createURLFromBase64(urlBase64, "image/jpeg"));
    }
  };

  const getRoles = useParameters("roles");
  const { data: dataRoles, isLoading: isLoadingRoles } = getRoles;
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (dataRoles) {
      setRoles(
        dataRoles.data.data.roles.map((ele) => {
          return {
            id: ele.id,
            name: ele.name,
          };
        })
      );
    }
  }, [dataRoles]);

  const handleChangeUser = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newUserForm = userForm;

    newUserForm[id] = value;

    setUserForm(newUserForm);
    setForceUpdate(!forceUpdate);
  };
  const handleSelect = (value, id) => {
    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newUserForm = userForm;
    newUserForm[id] = value;

    setUserForm(newUserForm);
    setForceUpdate(!forceUpdate);
  };
  const handleRadio = (event) => {
    const { value } = event.target;

    const newErrorForm = errorForm;
    newErrorForm["role"].error = false;
    newErrorForm["role"].message = "";
    setErrorForm(newErrorForm);

    const newUserForm = userForm;
    newUserForm["role"] = value;
    setUserForm(newUserForm);
    setForceUpdate(!forceUpdate);
  };
  const handleEditUser = () => {
    toast.remove();
    const validation = validateEditUserForm.validate(userForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      const formData = {
        id: users.selectedUser.id,
        name: userForm.name,
        lastname: userForm.lastname,
        rolId: userForm.role,
        clinicId: userForm.clinic,
        email: userForm.email,
        phone: userForm.phone === "" ? null : userForm.phone,
        address: userForm.address,
        product_commission:
          userForm.productCommission === ""
            ? 0
            : Number(userForm.productCommission),
        treatment_commission:
          userForm.treatmentCommission === ""
            ? 0
            : Number(userForm.treatmentCommission),
        image:
          imageLogo === null || imageLogo.includes("http")
            ? null
            : `data:image/png;base64,${imageLogo}`,
      };
      if (userForm.newPassword) {
        formData.password = userForm.newPassword;
      }
      editUser.reset();
      editUser.mutate(formData, {
        onSuccess: () => {
          setShowModal(true);
        },
        onError: (err) => {
          toast.error(
            err.response.data.error.message || "Error trying to edit an user"
          );
        },
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    navigate(-1);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <Modal open={showModal} onCancel={handleClose} width={"407px"}>
        <Modal.Header
          icon={userCheck}
          iconBorderColor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 52px 0px 52px"}
          title={"The information has been updated succesfully"}
          weightTitle={theme.fonts.weight.semibold}
        />
        <Modal.Body
          margin="24px 0 0 0"
          maxHeight={200}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" p="0px 60px 0px 60px">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleClose}
                width="100%"
                background={theme.colors.green}
              >
                Go to user list
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <Row height="18px" align="center" m="0px 0px 37px 0px">
        <Link
          onClick={() => navigate(-1)}
          size={theme.fonts.size.sm}
          color={theme.colors.green}
          style={{ gap: "5px" }}
        >
          <ChevronLeftIcon stroke={theme.colors.green} />
          Go back
        </Link>
      </Row>

      <Row height="40px" align="center" m="0px 0px 37px 0px">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Edit User
          </Text>
        </Div>
      </Row>

      <Row m="0px 0px 28px 0px" justify="center">
        <UserCard>
          {isLoadingRoles ? (
            <Div
              direction="column"
              gap="30px"
              align="center"
              width="100%"
              height="100%"
              p="20px"
            >
              <Skeleton.Avatar active size={158} />
              <Skeleton active />
            </Div>
          ) : (
            <FormUserCard
              handleUploadLogo={handleUploadLogo}
              imageLogo={imageLogo}
              prevImageLogo={prevImageLogo}
              handleChangeUser={handleChangeUser}
              userForm={userForm}
              errorForm={errorForm}
              handleRadio={handleRadio}
              roles={roles}
            />
          )}
        </UserCard>

        <InfoCard>
          {isLoadingRoles ? (
            <Div
              direction="column"
              align="start"
              gap="30px"
              width="100%"
              height="100%"
              p="40px"
            >
              <Skeleton.Input active size={50} />
              <Skeleton active paragraph={{ rows: 2 }} />
              <Skeleton.Input active size={50} />
              <Skeleton active paragraph={{ rows: 2 }} />
            </Div>
          ) : (
            <FormInfoCard
              handleChangeUser={handleChangeUser}
              errorForm={errorForm}
              handleSelect={handleSelect}
              userForm={userForm}
              optionsClinics={optionsClinics}
            />
          )}
        </InfoCard>
      </Row>

      <Row align="center" m="0px 0px 37px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          color={theme.colors.white}
          background={theme.colors.green100}
          border={theme.colors.green}
          onClick={() => navigate(-1)}
          width="190px"
          m="0 24px 0 0"
        >
          Cancel
        </Button>
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          onClick={handleEditUser}
          loading={editUser.isLoading}
          width="190px"
          background={theme.colors.green}
        >
          Save Information
        </Button>
      </Row>
    </Container>
  );
};
